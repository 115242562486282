import { DAY_IN_SECONDS } from '@constants/time';
import { getNotFound } from '@lib/prepr/404';
import { getConfig } from '@lib/prepr/config';
import Hero from '@organisms/Hero/Hero';
import Sections from '@organisms/Sections/Sections';
import { Locale } from '@type-declarations/locale';
import { NotFoundPage } from '@type-declarations/page';

function Custom404Page({
  page: { title, introText, image, cta, heroContent, sections },
}: {
  page: NotFoundPage;
}) {
  return (
    <>
      <Hero title={title} text={introText} image={image} ctaBottom={cta}>
        {heroContent.length && (
          <Sections sections={heroContent} modifier="heroSection" />
        )}
      </Hero>

      <Sections sections={sections} />
    </>
  );
}

export async function getStaticProps({ locale }: { locale: Locale }) {
  const [page, config] = await Promise.all([
    getNotFound({ locale }),
    getConfig({ locale }),
  ]);

  return {
    props: {
      locale,
      page,
      config,
    },
    revalidate: DAY_IN_SECONDS,
  };
}

export default Custom404Page;
